import { Client, Storage, Databases, Account, ID, Query } from "appwrite";

const   API_URL="https://api.surikwat.com/v1";
const   ID_PROJECT="64888464ebe42ec316a8";
const   client = new Client()
  .setEndpoint(API_URL) // Your Appwrite Endpoint
  .setProject(ID_PROJECT);
const   databases = new Databases(client);
const   ID_DB = "63e56ab3e4409acf0313";
const   ID_PARAMETRES_COMMUNS="ID_PARAMETRES_COMMUNS";
const   ID_DB_USER = "ID_STUDENT_API";
const   ID_DB_TD_FAC = "6477cc8edd51b678fbaa";
const storage = new Storage(client);
const account = new Account(client);

const getParamCommuns = async (city) => 
{
  const villeUserStorage = localStorage.getItem('villeUser');
  if (villeUserStorage) city = villeUserStorage;
  const params = await databases.listDocuments(
    ID_DB,
    ID_PARAMETRES_COMMUNS,
    [Query.equal('ville',city)]
  );
  const storage_PARAM = params.documents[0];
      
  localStorage.setItem("semestre",params.documents[0].current_semestre );
  localStorage.setItem("storage_PARAM", JSON.stringify(storage_PARAM));
  return (storage_PARAM);
}

const getAllCity = async () => 
{
    const cities = await databases.listDocuments(
        ID_DB,
        ID_PARAMETRES_COMMUNS,
        [Query.select(["ville"])]
    );
    return (cities.documents);
}

const getFromGoogle = async (parametre) => {
  let urlApiModif = `${API_URL}?${parametre}`;
  const request = new Request(urlApiModif, {
    method: "GET",
    headers: {
      "Content-Type": "text/plain;charset=utf-8",
    },
  });
  let returnApi = await fetch(request);
  return await returnApi.json();
};

async function getTitre(city) {
  const {welcome_title} = (await getParamCommuns(city)) ;
  return (welcome_title);
}

const   getMessage = async (city) =>
{
    const {wecome_text} = (await getParamCommuns(city)) ;
    return (wecome_text);
};

const checkFormDone = async (mail) =>
{  
  const semestre = localStorage.getItem('semestre');
  const td = await databases.listDocuments(
    ID_DB,
    'ETU_TD_DECLAR',
    [Query.equal('DECLARE_STUDENT_MAIL',mail)
    ,Query.startsWith('DECLAR_SEM',semestre)]
  );
  if (td.total >= 1)
    return (true);
  else 
    return (false);
}

async function checkUser(mail) {
  let user = await databases.listDocuments(
    ID_DB,
    ID_DB_USER,
    [Query.equal('mailUser',mail)]
  );
  if (user.total === 1)
  {
    if (await checkFormDone(user.documents[0].mailUser))
      user.state = 'Déja remplis';
    else 
      user.state = 'A faire';
  }
  else  user.state = 'INCONNUS'
  return (user);
}

async function getAnswersForOneStudents(mail) {
  let data = await getFromGoogle(
    `action=getAnswersForOneStudents&mail=${mail}`
  );
  return data.data;
}
async function saveFomulaire(responses, city) {
  const currentDate = new Date()
  let newTdArray = [];
  const currentYear = currentDate.getFullYear()
  responses.DECLAR_TD.forEach(onTD => {     
    newTdArray.push(JSON.stringify(onTD));
  })
  const sem_year = responses.DECLAR_SEM + currentYear.toString();
  try {
  await databases.createDocument(
    ID_DB,
    "ETU_TD_DECLAR",
    ID.unique(),
    {
    DECLARE_DATE:currentDate,
    DECLAR_COMMENTAIRE:responses.DECLAR_COMMENTAIRE,
    eTUDIANTS:responses.eTUDIANTS,
    DECLAR_SEM:sem_year,
    DECLAR_YEAR :currentYear,
    DECLARE_STUDENT_MAIL: responses.DECLARE_STUDENT_MAIL,
    TDS:newTdArray
    }
  );

  }
  catch (e)
  {
    return false;
  }
  return true;
}

async function getTD(groupe, ville, semestre) {
  const tdDB = await databases.listDocuments(
    ID_DB,
    ID_DB_TD_FAC,
    [ Query.equal('TD_VILLE',ville),
      Query.equal('TD_GROUPE',groupe),
      Query.equal('TD_SEMESTRE',semestre),
      Query.equal('TD_ACTIVE',true),
    ]
  );
  return tdDB.documents;
}

function getDays() {
  return ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
}

function getHoraires() {
  return [
    "8h00 - 9h30",
    "9h30 - 11h00",
    "11h00 - 12h30",
    "12h30 - 14h00",
    "13h00 - 14h30",
    "14h00 - 15h30",
    "14h30 - 16h00",
    "16h00 - 17h30",
    "17h00 - 18h30",
    "17h30 - 19h00",
    "19h00 - 20h30",
  ];
}

export const api = {
  checkUser,
  getTD,
  getDays,
  getHoraires,
  getTitre,
  getMessage,
  saveFomulaire,
  getAnswersForOneStudents,
  storage,
  Query,
  account,
  ID,
  getAllCity,
  getParamCommuns
};