import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";

function Fin() {
  const navigate = useNavigate();

  const removeLocalStorageInformation = () => {
    localStorage.removeItem("idUser");
    localStorage.removeItem("groupe");
  };

  const goToHomeRoot = () => navigate(`/`);

  const cleanInformationsInLocalStorage = () => {
    if (localStorage.getItem("idUser")) removeLocalStorageInformation();
    else goToHomeRoot();
  };

  useEffect(() => {
    cleanInformationsInLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className="card" id="BYE">
        <div className="card-content">
          <p>
            Merci d'avoir répondu à ce formulaire. Toute l'équipe vous souhaite
            une bonne journée.
          </p>
        </div>
      </div>
    </>
  );
}

export default Fin;
