import HeaderCity from "../../component/HeaderCity/HeaderCity";
import AvaibleCity from "../../component/AvaibleCity/AvaibleCity"

function Accueil() {
    return ( 
        <>
            <HeaderCity />
            <AvaibleCity />
        </>
     );
}

export default Accueil;