import Header from "../../component/Header/Header";
import Connexion from "../../component/Connexion/Connexion"
import { useParams } from "react-router-dom";

function Accueil() {
    const {cityName} = useParams();

    return ( 
        <>
            <Header cityName={cityName} />
            <Connexion cityName={cityName} />
        </>
     );
}

export default Accueil;