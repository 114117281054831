import headerlogo from "../../images/header.png";
import "./HeaderCity.css";
/*
Page qui sera afficher en haut de la page de choix de ville
*/
function HeaderCity() {
  return (
    <div className="container ">
      <div className="card">
        <header className="card-header">
          <img src={headerlogo} alt="logo" />
          <p id="card-header-title">Merci de choisir votre ville :</p>
        </header>
      </div>
    </div>
  );
}

export default HeaderCity;
