/* eslint-disable react-hooks/exhaustive-deps */
/*
composant qui va gérer la connexion a partir de la vérification de l'email.
Il n'y a pas de gestion de login/password, chaque personnes s'identifie grace à son mail
-- Une déclaration par utilisateur.
-- Si le mail n'est pas dans dans la bdd pas d'access à la page suivante.

*/
import React, { useEffect, useState } from "react";
import "bulma/css/bulma.min.css";
import "./Connexion.css";
import { api } from "../../lib/api";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Connexion({cityName, mailFromJEP}) {
  //variable pour le stockage de l'erreur
  const [erreur, setErreur] = useState({ typeErreur: "", messageErreur: "" });
  //variable stocker le mail
  const [mail, setMail] = useState("");
  const [messageFormulaire, setMessageFormulaire] = useState("");
  const [isloading, setIsloading] = useState(true);
  const navigate = useNavigate();

  const saveInformations = (fromAPI) => {
    const { num, groupeUSer, villeUser, nameUser, state, mailUser } = fromAPI;

    localStorage.setItem("user_db_id", fromAPI.$id);
    localStorage.setItem("num", num);
    localStorage.setItem("groupeUSer", groupeUSer);
    localStorage.setItem("villeUser", villeUser);
    localStorage.setItem("nameUser", nameUser);
    localStorage.setItem("state", state);
    localStorage.setItem("mailUser", mailUser);
  };

  const checkMAil = async (mailToCheck) => {
    //fonction qui vérifie le mail et si il est bien formater envoi au back pour vérification
    setIsloading(true);
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    //en cas de mauvais formatage on affiche l'erreur sur le front.
    if (!mailToCheck.match(mailformat))
      setErreur({
        typeErreur: "is-danger",
        messageErreur:
          "ATTENTION : mail non valide, merci de vérifier votre saisie ",
      });
    else {

      try {
        let apiData = await api.checkUser(mailToCheck);
        if (apiData.state === "A faire" )
          {
            saveInformations(apiData.documents[0]);
            navigate(`/SetPicture`);
          }
        else
          if (apiData.state === "Déja remplis")           
          setErreur({
            typeErreur: "is-info",
            messageErreur:
              "⚠️ Vous avez déjà rempli la déclaration, pour la modifier veuillez nous contacter à l'adresse jurisperform@gmail.com",
          });
          else 
          setErreur({
            typeErreur: "is-warning",
            messageErreur:
              "⛔ Adresse e-mail inconnue, veuillez vérifier ou nous contacter à l'adresse jurisperform@gmail.com.",
          });
      } catch (err) {
        setErreur({
          typeErreur: "is-danger",
          messageErreur:
            " 🔻 Erreur de connexion, veuillez vous connecter plus tard.",
        });
      }
    }
    //setIsloading(false);
  };

  const getMessage = async () => {
    setMessageFormulaire(await api.getMessage(cityName));
    setIsloading(false);
  };

  useEffect(() => {
    setIsloading(true);
    if (mailFromJEP)
      checkMAil(mailFromJEP);
    getMessage();
    setIsloading(false);
  }, []);

  const handleChange = (e) => {
    //Fonction qui gére l'input et le state du mail
    const { value } = e.currentTarget;

    setMail(value);
  };

  if (isloading) {
    return <Loader />;
  } else {
    return (
      <div className="container">
        <div className="card HOME " id="HOME">
          <div className="card-header ">
            {/* Message de bienvenu*/}
            <div className="content">{messageFormulaire}</div>
          </div>
          {/* formulaire */}
          <div className="card-content">
            <label className="label">Email : </label>
            <div className="control has-icons-left has-icons-right">
              <input
                id="inputMail"
                className="input"
                type="email"
                value={mail}
                onChange={handleChange}
                name="mail"
                placeholder="Votre email"
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <button
                id="validMAil"
                className="button is-info is-light"
                onClick={() => checkMAil(mail)}>
                Suivant
              </button>
            </div>
            {/* block qui affichera le msg d'erreur */}
            {erreur.messageErreur && (
              <div className="block messageBlock">
                <div
                  id="alertWARNING"
                  className={"message " + erreur.typeErreur}>
                  <div className="message-body help is-info errorMessage">
                    {erreur.messageErreur}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Connexion;
