/*
Composant qui va contenir toute la gestion du formulaire
inscription au TDs etc...
*/
import { useEffect, useState } from "react";
import { api } from "../../lib/api";
import { FaTimes, FaCheck } from "react-icons/fa/";
import "./Formulaire.css";
import "bulma/css/bulma.min.css";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

function Formulaire() {
  const [tdDisponibles, setTdDisponibles] = useState([]);
  const [daySelect, setDaySelect] = useState("Lundi");
  const [reponses, setReponses] = useState({ tds: [] });
  const [enCours, setEnCours] = useState(true);
  const [daysDispo, setDaysDispo] = useState([]);
  const [horaires, setHoraires] = useState([]);
  const [verif, setVerif] = useState(false);
  const [currentIdTd, setCurrentIdTd] = useState(0);
  const [creneauPerso, setCreneauPerso] = useState({
    day: "Lundi",
    hDebut: "08:00",
    hFin: "10:00",
  });
  const [isloading, setIsloading] = useState(true);
  const [commentaire, setCommentaire] = useState("");
  const [erreurSaveApi, setErreurSaveApi] = useState(false);
  const [okSaveApi, setOkSaveApi] = useState(false);
  const navigate = useNavigate();
  const [ville, setVille] = useState(false);
  const [colorCurrentTD, setColorCurrentTD] = useState("#f88220");
  let [infoUtilisateur, setInfoUtilisateur] = useState({
    idUser: "",
    groupe: "",
    nom: "",
  });

  const updateTd = async (groupe, ville, semestre) => {
    const tds = await api.getTD(groupe, ville, semestre);
    //
    let createReponse = [];
    let newTd = [];
    //on boucle sur les tds pour créer un tableau qui contiendra les futurs réponses
    tds.forEach((td) => {
      createReponse.push({
        td: td.TD_NAME,
        jour: "",
        hDebut: "",
        hFin: "",
        assiste: true,
        perso: false,
      });
      newTd.push(td.TD_NAME);
    });
    setTdDisponibles(newTd);
    setReponses({ tds: createReponse });
    setIsloading(false);
  };

  const updatePlanning = async (mail) => {
    setIsloading(true);
    api.getAnswersForOneStudents(mail);
    const infoFromApi = await api.getAnswersForOneStudents(mail);
    infoFromApi.tds.forEach((td) => {
      const currentTd = JSON.parse(td);
      const { jour, hDebut, hFin } = currentTd;

      addReponse(
        jour,
        `${hDebut.replaceAll(":", "h")} - ${hFin.replaceAll(":", "h")}`
      );
    });

    setIsloading(false);
  };
  const updateInformations = () => {
    const id = localStorage.getItem("num");
    const user_db_id = localStorage.getItem("user_db_id");
    const groupe = localStorage.getItem("groupeUSer");
    const villeLocal = localStorage.getItem("villeUser");
    const nom = localStorage.getItem("nameUser");
    const semestre = localStorage.getItem("semestre");
    setVille(villeLocal);
    setInfoUtilisateur({
      user_db_id: user_db_id,
      num: id,
      groupeUSer: groupe,
      nameUser: nom,
      DECLAR_SEM: semestre,
    });
    updateTd(groupe, villeLocal, semestre);
    //on charge les jours de la semaine et les horaires depuis la DB
    const daysDB = api.getDays();
    const horaireDb = api.getHoraires();
    //on créer une variable temporaire pour créer le nouveau tableau
    setDaysDispo(daysDB);
    setHoraires(horaireDb);
  };

  useEffect(() => {
    //on charge les information depuis le storage
    if (localStorage.getItem("num")) {
      const state = localStorage.getItem("state");
      const mail = localStorage.getItem("mailUser");
      updateInformations();
      if (state === "Déja remplis" && tdDisponibles.length > 0) {
        updatePlanning(mail);
      }
    } else navigate(`/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveFormulaire = async () => {
    setIsloading(true);
    const mail = localStorage.getItem("mailUser");

    const formatageResponse = {
      DECLAR_TD: reponses.tds,
      DECLAR_COMMENTAIRE: commentaire,
      num: infoUtilisateur.num,
      eTUDIANTS: infoUtilisateur.user_db_id,
      DECLARE_STUDENT_MAIL: mail,
      DECLAR_SEM: infoUtilisateur.DECLAR_SEM,
      action: "new",
    };
    const save_to_api = await api.saveFomulaire(formatageResponse, ville);
    if (save_to_api) setOkSaveApi(true);
    else setErreurSaveApi(true);
  };

  const handleCommentaireChange = (event) => {
    setCommentaire(event.target.value);
  };

  const nextId = () => {
    let i = -1;
    reponses.tds.forEach((td, index) => {
      if (i === -1 && td.jour === "" && td.assiste) i = index;
    });
    setCurrentIdTd(i);
    setColorCurrentTD("#" + ((Math.random() * 0xffffff) << 0).toString(16));
  };

  const removeReponse = (i) => {
    const rep = reponses.tds;
    //on réinitialise le boutons dans le formulaire
    if (rep[i].assiste && !rep[i].perso) {
      let btn = document.getElementById(
        rep[i].jour +
          rep[i].hDebut.replaceAll(":", "h") +
          "-" +
          rep[i].hFin.replaceAll(":", "h")
      );
      btn.innerHTML = "➕";
      btn.disabled = false;
      btn.classList.remove("is-success");
    }
    //suppresion de la ligne dans le tableau provisoire
    rep[i] = {
      td: tdDisponibles[i],
      jour: "",
      hDebut: "",
      hFin: "",
      assiste: true,
    };
    //on injecte le tableau provisoire dans la réponse
    setReponses({ ...reponses, tds: rep });
    //on modifie la variable en cours en vrai car dans tous les cas on continue le formulaire
    setEnCours(true);

    nextId();
  };

  const handlechange = (e) => {
    const { name, value } = e.currentTarget;
    if (name === "daySelect") setDaySelect(value);
    else setCreneauPerso({ ...creneauPerso, [name]: value });
  };

  const updatePerso = () => {
    //si le formulaire est encore en cours
    if (enCours) {
      //on mets a jours les réponses
      updateResponses(
        creneauPerso.day,
        creneauPerso.hDebut,
        creneauPerso.hFin,
        true
      );
      //on réinitialise la variable perso
      setCreneauPerso({ day: "Lundi", hDebut: "08:00", hFin: "10:00" });
    }
  };

  const countReponse = () => {
    return reponses.tds.reduce((count, td) => {
      if (td.jour !== "" || !td.assiste) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const updateResponses = (d, hDeb, hFin, perso) => {
    //variable tempo pour récupére les réponses.
    const rep = reponses.tds;

    //enregistrement de la ligne dans le tableau provisoire
    rep[currentIdTd] = {
      td: tdDisponibles[currentIdTd],
      jour: d,
      hDebut: hDeb,
      hFin: hFin,
      assiste: true,
      perso: perso,
    };
    //mise a jour du state réponse
    setReponses({ ...reponses, tds: rep });
    //si on a le currentid +1 et égal a la taille du tableau on arrete tout sinon on incrémente le currentID
    countReponse();
    if (countReponse() === reponses.tds.length) setEnCours(false);
    else nextId();
  };
  const updateNoSession = () => {
    const rep = reponses.tds;
    rep[currentIdTd] = { td: tdDisponibles[currentIdTd], assiste: false };
    setReponses({ ...reponses, tds: rep });
    //si on a le currentid +1 et égal a la taille du tableau on arrete tout sinon on incrémente le currentID
    if (currentIdTd + 1 === reponses.tds.length) setEnCours(false);
    else nextId();
  };

  const addReponse = (d, h) => {
    if (enCours) {
      let btn = document.getElementById(d + h.replaceAll(" ", ""));
      const creneau = h.replaceAll(" ", "").replaceAll("h", ":").split("-");
      btn.disabled = true;
      btn.innerHTML = tdDisponibles[currentIdTd].slice(0, 10);
      btn.classList.add("is-success");
      updateResponses(d, creneau[0], creneau[1], false);
    }
  };
  if (erreurSaveApi)
    return (
      <div className="card">
        <div className="notification is-danger">
          Oops ! Il semblerait que notre formulaire de saisie ait décidé de
          faire des siennes. <br />
          Ne vous inquiétez pas, nous sommes là pour vous aider à résoudre ce
          problème ! <br />
          Si vous avez rencontré une erreur de sauvegarde, nous vous prions de
          bien vouloir réessayer en cliquant sur le bouton de sauvegarde.
          Parfois, même les meilleures machines ont besoin d'un petit coup de
          pouce pour bien fonctionner.
          <br />
          Maintenant, si vous avez déjà tenté de recommencer plusieurs fois sans
          succès, il est temps de faire appel à notre équipe du secrétariat. Ils
          sont prêts à voler à votre secours et à résoudre ce problème de
          manière magistrale ! <br />
        </div>
        <button className="button is-link" onClick={() => navigate(`/`)}>
          Retour
        </button>
      </div>
    );
  if (okSaveApi)
    return (
      <div className="card">
        <div className="notification is-info">
          Merci d'avoir déclaré vos TD avec diligence.
          <br />
          Votre engagement témoigne de votre sérieux. <br />
          Comme le disait le célèbre juriste américain Oliver Wendell Holmes Jr.
          :<br />
          "La loi est l'expression de la volonté de la société, et son
          interprétation doit être guidée par la recherche de la justice".{" "}
          <br />
          Nous espérons que cette année en notre compagnie sera l'occasion pour
          vous d'approfondir vos connaissances juridiques et de développer votre
          sens de la justice.
          <br />
          "Crescit sub pondere virtus" - La vertu croît sous le poids des
          difficultés.
          <br />
          "Quidquid latine dictum sit, altum videtur... si non intellegitur." -
          Perceval de Galles
        </div>
      </div>
    );

  if (isloading) <Loader />;
  else {
    return (
      <div className="card" id="QUESTIONNAIRE">
        <div className="card-content">
          <p></p>
          <p className="subtitle" hidden={!enCours}>
            Bonjour {infoUtilisateur.nameUser},<br />
            merci de saisir dans le planning suivant votre créneau pour le{" "}
            <br />{" "}
            <span className={`tag`} style={{ backgroundColor: colorCurrentTD }}>
              {tdDisponibles[currentIdTd]}
            </span>
          </p>
        </div>
        <div className="card-content">
          <table className="table" id="tableBig" hidden={!enCours}>
            <thead>
              <tr>
                <th className="jours"></th>
                <th className="jours">Lundi</th>
                <th className="jours">Mardi</th>
                <th className="jours">Mercredi</th>
                <th className="jours">Jeudi</th>
                <th className="jours">Vendredi</th>
                <th className="jours">Samedi</th>
              </tr>
            </thead>
            <tbody>
              {horaires.map((horaire, index) => (
                <tr key={index}>
                  <td className="day">
                    <div></div>
                    <h3>{horaire}</h3>
                  </td>
                  {daysDispo.map((dayDispo, index) => (
                    <td key={index} className="date">
                      <button
                        className="btnDate button"
                        id={dayDispo + horaire.replaceAll(" ", "")}
                        onClick={() => addReponse(dayDispo, horaire)}>
                        ➕
                      </button>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <table className="tableSmall" hidden={!enCours}>
            <thead>
              <tr>
                <th className="jours"></th>
                <th className="jours">
                  Jour :{" "}
                  <select
                    className="input"
                    value={daySelect}
                    name="daySelect"
                    onChange={handlechange}>
                    <option>Lundi</option>
                    <option>Mardi</option>
                    <option>Mercredi</option>
                    <option>Jeudi</option>
                    <option>Vendredi</option>
                    <option>Samedi</option>
                  </select>
                </th>
              </tr>
            </thead>
            <tbody>
              {horaires.map((horaire, index) => (
                <tr key={index}>
                  <td className="day">
                    <div></div>
                    <h3>{horaire}</h3>
                  </td>
                  <td className="date">
                    <button
                      className="btnDate button"
                      id={daySelect + horaire.replaceAll(" ", "")}
                      onClick={() => addReponse(daySelect, horaire)}
                      disabled={!enCours}>
                      ➕
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="divNotGoing" hidden={!enCours}>
            <button
              id="noTD"
              className="button is-danger is-light"
              onClick={() => updateNoSession()}
              disabled={!enCours}>
              Je n'assiste pas à ce TD.
            </button>
          </div>
          <div className="block" id="horairePerso " hidden={!enCours}>
            <p className="subtitle">
              Si vous ne trouvez pas le créneau de votre TD dans le planning
              merci de le renseigner :{" "}
            </p>
            <div className="horairePerso">
              <div className="field">
                <label className="label">Jour</label>
                <div className="select" id="dayPerso">
                  <select
                    id="selectDayPerso"
                    value={creneauPerso.day}
                    name="day"
                    onChange={handlechange}>
                    {daysDispo.map((dayDispo, index) => (
                      <option key={index} value={dayDispo}>
                        {dayDispo}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="field">
                <label className="label">Début</label>
                <input
                  className="input horaireInput"
                  id="startPerso"
                  onChange={handlechange}
                  value={creneauPerso.hDebut}
                  name="hDebut"
                  type="time"
                />
              </div>
              <div className="field">
                <label className="label">Fin</label>
                <input
                  className="input horaireInput"
                  id="endPerso"
                  onChange={handlechange}
                  value={creneauPerso.hFin}
                  name="hFin"
                  type="time"
                />
              </div>
              <div className="buttHoraireAdd field">
                <button
                  className="button is-primary"
                  id="savHorairePerso"
                  onClick={() => updatePerso()}
                  disabled={!enCours}>
                  <FaCheck />
                </button>
              </div>
            </div>

            <div id="alertER" hidden>
              <p className="notification is-danger is-light">
                Attention : vous avez déjà un TD pour ce créneau :
              </p>
            </div>
          </div>
          <div className="divHperso block is-1" id="divHperso">
            <h3 className="title is-3">Récapitulatif : </h3>
            <table className="table">
              <thead>
                <tr>
                  <th>TD</th>
                  <th>Jour</th>
                  <th>Début</th>
                  <th>Fin</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {reponses.tds.map((reponse, index) => (
                  <tr key={index}>
                    <td>{reponse.td}</td>
                    {reponse.assiste ? (
                      <>
                        <td>{reponse.jour}</td>
                        <td>{reponse.hDebut}</td>
                        <td>{reponse.hFin}</td>
                      </>
                    ) : (
                      <td colSpan="3">Je n'y assiste pas </td>
                    )}
                    <td>
                      {(reponse.jour !== "" || !reponse.assiste) && (
                        <button
                          className="button is-danger"
                          onClick={() => removeReponse(index)}>
                          <FaTimes />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-content">
          <p className="subtitle">Commentaire destiné à l'administration : </p>
          <div className="card-content">
            <textarea
              className="textarea"
              onChange={handleCommentaireChange}
              id="commentaire"
              name="commentaire"
              placeholder="Commentaire à destination de l'administration"></textarea>
          </div>
        </div>

        <footer className="card-footer">
          <div className="card-footer-item validateFooter">
            <div className="notification verif" hidden={enCours}>
              Merci de vérifier vos créneaux avant de valider.
            </div>
            <label className="checkbox">
              <input
                type="checkbox"
                disabled={enCours}
                onClick={() => setVerif(!verif)}
              />
              <label className="certif">
                Je certifie avoir bien vérifié mes horaires.
              </label>
            </label>
            <div className="btnSave">
              <button
                className="button is-primary"
                id="savPlanning"
                onClick={() => saveFormulaire()}
                disabled={!verif}>
                Enregistrer
              </button>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Formulaire;
