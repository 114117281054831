import { useEffect, useState } from "react";
import headerlogo from "../../images/header.png";
import { api } from "../../lib/api";
import "./Header.css";
/*
Page qui sera afficher en haut de chaque pages.

*/
function Header({ cityName }) {
  const [paramCommuns, setParamCommuns] = useState({});

  const getTitre = async () => {
    setParamCommuns(await api.getParamCommuns(cityName));
  };

  useEffect(() => {
    getTitre();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container ">
      <div className="card">
        <header className="card-header">
          <img src={headerlogo} alt="logo" />
          <p id="card-header-title" className="title is-1 is-spaced">
            {paramCommuns.welcome_title}
          </p>
          <div id="card-header-title ">
            <p className="subtitle is-3 is-spaced cityname">
              {paramCommuns.ville}
            </p>{" "}
            Semestre : {paramCommuns.current_semestre}
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;
