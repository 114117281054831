import Header from "../../component/Header/Header";
import Connexion from "../../component/Connexion/Connexion"
import { useParams } from "react-router-dom";

function FromJEP() {
    const {cityName, mail} = useParams();

    return ( 
        <>
            <Header cityName={cityName} />
            <Connexion cityName={cityName} mailFromJEP={mail}/>
        </>
     );
}

export default FromJEP;