import React, { useState, useEffect } from "react";
import { api } from "../../lib/api";
import Header from "../Header/Header";
import "./SetPicture.css";
import "bulma/css/bulma.min.css";
import "../Connexion/Connexion.css";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

function SetPicture() {
  const { storage, Query, ID } = api;
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [nom, setNom] = useState();
  const [mail, setMail] = useState();
  const [bucketID, setBucketID] = useState();
  const navigate = useNavigate();

  const uploadImage = async (e) => {
    e.preventDefault();
    try {
      const maxWidth = 350;
      const maxHeight = 350;

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        if (img.width > maxWidth || img.height > maxHeight) {
          if (img.width / maxWidth > img.height / maxHeight) {
            canvas.width = maxWidth;
            canvas.height = (img.height / img.width) * maxWidth;
          } else {
            canvas.height = maxHeight;
            canvas.width = (img.width / img.height) * maxHeight;
          }
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
        }
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async function (blob) {
          const newName = mail.toLowerCase() + ".png"; // le nouveau nom pour l'image
          const renamedImageFile = new File([blob], newName, {
            type: image.type,
          });
          const newImage = await storage.createFile(
            bucketID,
            ID.unique(),
            renamedImageFile
          );
          if (newImage) alert("Votre photo a bien été reçue ! ");
          navigate(`/formulaire`);
        });
      };
    } catch (error) {
      console.log(`${error.message}`);
    }
  };

  const verifPicture = async (mail, b) => {
    const bucketsFiles = await storage.listFiles(b, [
      Query.equal("name", mail + ".png"),
    ]);
    const pictureInfos = bucketsFiles.files;
    let result = pictureInfos.filter((obj) => obj.name === mail + ".png");
    if (result.length > 0) navigate(`/formulaire`);
  };

  const updateInfos = async () => {
    if (localStorage.getItem("num")) {
      const nom = localStorage.getItem("nameUser");
      const ville = localStorage.getItem("villeUser");
      setNom(nom);
      const mail = localStorage.getItem("mailUser");
      setMail(mail);
      const bId = `BK_${ville.toUpperCase()}_STUDENT`;
      setBucketID(bId);
      verifPicture(mail, bId);
    } else {
      navigate(`/`);
    }
  };

  useEffect(() => {
    updateInfos();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className="container">
        <div className="card HOME " id="HOME">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="card-header ">
                <div className="content">
                  <p>Bonjour {nom} 🎓 </p>
                  <b className="warningText">⚠️ Attention ⚠️ </b>
                  <br />
                  Afin de compléter votre dossier étudiant, il est obligatoire
                  de téléverser votre photo. Nous vous recommandons de choisir
                  une photo avec une taille minimale de 500x500 pixels, au
                  format JPEG ou PNG et avec une taille maximale de 5 Mo. En
                  téléversant votre photo souriante, vous facilitez la création
                  de votre dossier et vous êtes prêt à déclarer vos TD!
                </div>
              </div>
              <div className="card-content">
                <form>
                  <div className="file is-info has-name">
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        name="resume"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          setFile(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>

                        <span className="file-label">
                          Choisir votre photo …
                        </span>
                      </span>
                      {image && <span className="file-name">{image.name}</span>}
                    </label>
                  </div>
                  <br />
                  {image && (
                    <>
                      <br />
                      <img className="previewFile" src={file} alt="preview" />
                      <br />
                      <button
                        className="button is-info is-light"
                        onClick={(e) => uploadImage(e)}>
                        Téléverser
                      </button>
                    </>
                  )}
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SetPicture;
