import "bulma/css/bulma.min.css";

function Loader() {
  return (
    <div>
      {/* bar de progression afficher lors de l'interogation du serveur. */}
      <div className="container">
        <div className="card HOME " id="HOME">
          <h3>Actualisation en cours</h3>
          <progress className="progress progress is-large is-info" max="100">
            100%
          </progress>
        </div>
      </div>
    </div>
  );
}
export default Loader;
