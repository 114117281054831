import Header from "../../component/Header/Header";
import Formulaire from "../../component/Formulaire/Formulaire"

function Inscription() {
    return ( 
        <>
            <Header />
            <Formulaire />
        </>
     );
}

export default Inscription;