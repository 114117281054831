import "./App.css";
import { Routes, Route } from "react-router-dom";

import CityChoice from './pages/CityChoice/CityChoice';
import Accueil from "./pages/Accueil/Accueil";
import FromJEP from "./pages/Accueil/FromJEP";
import Inscription from "./pages/Inscription/Inscription";
import Fin from "./component/Fin/Fin";
import SetPicture from "./component/SetPicture/SetPicture";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<CityChoice />}/>
        <Route path="/:cityName" element={<Accueil />}/>
        <Route path="/:cityName/:mail" element={<FromJEP />}/>
        <Route path="/formulaire/" element={<Inscription />} />
        <Route path="/SetPicture/" element={<SetPicture />} />
        <Route path="/fin/" element={<Fin />} />
      </Routes>
    </div>
  );
}
export default App;
