import "bulma/css/bulma.min.css";
import { useState, useEffect } from "react";
import { api } from "../../lib/api";
import { useNavigate } from "react-router-dom";

function AvaibleCity() {
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();
  const handleClick = (city) => navigate("/" + city);

  const getAllCities = async () => {
    setCities(await api.getAllCity());
  };

  useEffect(() => {
    //on supprime ce qu'il y a dans le storage
    localStorage.clear("storage_PARAM");
    getAllCities();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="card HOME " id="HOME">
          {cities.map((city, index) => (
            <button
              className="button is-primary"
              key={index}
              onClick={() => handleClick(city.ville)}>
              {city.ville}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
export default AvaibleCity;
